import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHeader from '../components/reusable/PageHeader';
import PriceTable from '../components/pricing/PriceTable';
import Phones from '../components/reusable/Phones';

const Pricing = () => (
  <Layout>
    <SEO title="Pricing" />
    <PageHeader title="Pricing" />
    <div className="container">
      <p>We offer premium marketing services for advertisers with advanced paid search needs. If you’re looking for a partner to take on some or all of your account management, learn how we can help you meet your search marketing goals.</p>
      <p>Optimozg Marketing Services is based on a kickstart $500 flat service fee plus a percent of your monthly paid search spend. Optimozg's Marketing Services are for accounts spending at least $5,000 per month on AdWords.</p>
    </div>
    <PriceTable />
    <Phones />
  </Layout>
)

export default Pricing
