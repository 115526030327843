import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PriceStyles from './PriceStyles';

const PriceTable = () => {
    const pricesData = useStaticQuery(graphql`
    query MyQuery {
        allContentfulPartnershipTypes(sort: {order: ASC, fields: orderNumber}) {
          edges {
            node {
              title
              orderNumber
              forWhom
              description
              percent
              includes
            }
          }
        }
      }
    `);

    // Find the longest list of included services and create a state with this list, each item set as false (inactive)
    var longest = pricesData.allContentfulPartnershipTypes.edges.reduce((a, b) => { 
        return a.node.includes.length > b.node.includes.length ? a : b; 
    });
    const includesWithState = longest.node.includes.map((include) => {
        return {title: include, active: false};
    });
    const [includedServices, setIncludedServices] = useState(includesWithState);
    const [activePartnershipType, setActivePartnershipType] = useState(null);

    const changePartnershipTypeAndServices = (orderNumber) => {
        setActivePartnershipType(orderNumber);

        const highlightedServices = includedServices.map((service) => {
            // see if a service is in the array of current partnership plan, and set to active if so
            const servicesInThisPartnership = pricesData.allContentfulPartnershipTypes.edges[orderNumber - 1].node.includes;

            if (servicesInThisPartnership.includes(service.title)) {
                return {...service, active: true};
            } else {
                return {...service, active: false};
            }
        });

        setIncludedServices(highlightedServices);
    }

    return (
        <div className="container">
            <h1 className="huge">Choose your partnership type:</h1>
            <PriceStyles>
                <div className="cards">
                    {pricesData.allContentfulPartnershipTypes.edges.map(({ node }) => {
                        return (
                            <div 
                                key={node.title} 
                                onClick={() => {changePartnershipTypeAndServices(node.orderNumber)}} 
                                className={activePartnershipType === node.orderNumber ? 'active' : undefined}
                            >
                                <div className="head">{node.title}</div>
                                <div className="content">
                                    <div className="text">
                                        <strong>{node.forWhom}</strong>
                                        <hr />
                                        {node.description}
                                    </div>
                                    <span className="label"><span>Price</span></span>                          
                                    <div className="percent">{node.percent}% of spend</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="includes">
                    <h2>Includes</h2>
                    <ul>
                        {includedServices.map((service) => {
                            return (
                                <li key={service.title} className={service.active ? 'active' : undefined}>{service.title}</li>
                            );
                        })}
                    </ul>
                    
                </div>
            </PriceStyles>
        </div>
        
    );
}


export default PriceTable;