import styled from 'styled-components';
import { device } from '../../constants/mediaQueries';

const PriceTableWrap = styled.div`
    .cards {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(290px, 320px));
        grid-gap: 15px;
        justify-content: center;

        @media ${device.deviceM} {
            grid-template-columns: repeat(4, 1fr);
        }
        > div {
            display: flex;
            flex-direction: column;
            flex-grow: 0;
            flex-shrink: 0;
            margin-bottom: 1rem;
            background: #f4f4f4;

            .head {
                display: flex;
                position: relative;
                padding: 20px 10px;
                width: 100%;
                overflow: hidden;
                text-align: center;
                color: #303f51;
                font-size: 20px;
                font-weight: 700;
                border-radius: 5px 5px 0 0;
                justify-content: center;
                align-items: center;
                transition: color .3s;

                &:after {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: transparent;
                    width: 100%;
                    height: 100%;
                    content: "";
                    transition: background-color .3s;
                    background-color: rgba(0,0,0,.1);   
                }
            }

            .content {
                display: flex;
                position: relative;
                padding: 25px 15px;
                width: 100%;
                pointer-events: none;
                flex-grow: 1;
                flex-direction: column;
                align-items: center;
                transition: color .3s;
                color: #6465b5;

                &:before {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: transparent;
                    width: 100%;
                    height: 100%;
                    content: "";
                    transition: background-color .3s;
                }

                .text {
                    z-index: 1;
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    margin-bottom: 2rem;

                    hr {
                        margin-top: 1rem;
                        margin-bottom: 1.5rem;
                        background: #6465b5;
                        z-index: 1;
                        width: 100%;
                    }
                }
            }

            &.active {
                box-shadow: 0 9px 35px rgba(0, 0, 0, 0.4);

                .content {
                    color: white;
                }

                .head {
                    color: white;
                }

                .percent {
                    color: white;
                }
            }

            &:first-child {
                .head {
                    background-color: #c9cfd9;    
                }

                &.active .content:before {
                    background-color: #c9cfd9;
                } 
            }

            &:nth-child(2) {
                .head {
                    background-color: #ead373;    
                }

                &.active .content:before {
                    background-color: #ead373;
                } 
            }

            &:nth-child(3) {
                .head {
                    background-color: #d5cbbd;    
                }

                &.active .content:before {
                    background-color: #d5cbbd;
                } 
            }

            &:last-child {
                .head {
                    background-color: #bebee6;    
                }

                &.active .content:before {
                    background-color: #bebee6;
                } 
            }

            .label {
                display: flex;
                width: 100px;
                height: 36px;
                border-radius: 0 50px 50px 0;
                background-image: linear-gradient(to right, #55bebf 0%, #134f99 100%);
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 2px;
                text-transform: uppercase;
                position: absolute;
                bottom: 25px;
                left: -6px;
                &:after {
                    position: absolute;
                    display: block;
                    content: "";
                    left: 0;
                    top: 100%;
                    width: 0;
                    height: 0;
                    border-width: 0 6px 6px 0;
                    border-color: transparent #134f99 transparent transparent;
                    border-style: solid;
                }
            }

            .percent {
                z-index: 1;
                color: #134f99;
                font-size: 18px;
                font-weight: 700;
                line-height: 26px;
                margin-left: auto;
                margin-bottom: 5px;
                margin-top: auto;
                padding-right: 10px;
                transition: color .3s;

                @media ${device.deviceM} {
                    font-size: 16px;
                    padding-right: 5px;
                }

                @media ${device.deviceL} {
                    font-size: 18px;
                    padding-right: 10px;
                }
            }
        }
    }

    .includes {
        margin-bottom: 20px;
        background-color: #FFF;
        padding: 35px;
        width: 100%;
        box-shadow: 0 12px 19px rgba(0, 0, 0, 0.1);

        h2 {
            display: block;
            margin-bottom: 25px;
            text-align: center;
            text-transform: uppercase;
            line-height: 1;
            letter-spacing: 1px;
            color: #134f99;
            font-size: 24px;
            font-weight: 300;
        }
        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            display: grid;
            grid-template-columns: 1fr;
            grid-auto-flow: row;
            grid-template-rows: repeat(7, 1fr);
            grid-gap: 10px 50px;

            @media ${device.deviceS}{
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(9, 1fr);
            }

            @media ${device.deviceL}{
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(6, 1fr);
            }
            li {
                opacity: 0.3;
                position: relative;
                padding-left: 40px;
                line-height: 1;
                color: #4a4a4a;
                font-size: 18px;
                font-weight: 300;
                margin-bottom: 0;

                &:before {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDIwIDE1Ij4gIDxwYXRoIGZpbGw9IiNmZjhlNDkiIGQ9Ik0uMjMgOC4yNWEuNy43IDAgMCAxIDAtMWwxLjA4LTFhLjc1Ljc1IDAgMCAxIDEgMGwuMDguMDggNC4yMyA0LjQyYS4zOC4zOCAwIDAgMCAuNTMgMEwxNy41NC4yM2guMDdhLjc3Ljc3IDAgMCAxIDEuMDYgMGwxLjA4IDEuMDVhLjcuNyAwIDAgMSAwIDFMNy40NiAxNC44MWEuNzUuNzUgMCAwIDEtMSAwbC02LTYuMy0uMjMtLjI2eiIvPjwvc3ZnPg==);
                    width: 20px;
                    height: 15px;
                    content: "";
                }

                &.active {
                    opacity: 1;
                }
            }
        }
    }
`;


export default PriceTableWrap;